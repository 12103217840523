<template>
  <div class="">
    <Button btnVariant="yellow" class="mx-2" @click="openUserStoreProductModal()">
      import from WooCommerce
    </Button>

    <!-- UserStore WooCommerceModal -->
    <ModalVue
      ref="WooCommerceModal"
      id="copyUserStoreProducts"
      title="<span class='px-3'>Add Products from WooCommerce</span>"
      size="xl">
      <div class="px-3">

     
      <div class="row">
        <div class="col-12 col-lg-4">
          <div class="d-flex align-items-center h-100">
            <FilterBox
              :search="true"
              placeholder="Search Products..."
              @input="searchProductQueryHandle($event)"></FilterBox>
          </div>
        </div>
        <div class="col-12 col-lg-8 pl-lg-0">
          <div
            class="d-flex py-2 py-lg-0 w-100 justify-content-start flex-row align-items-center">
            <div class="px-2" v-if="userStoreSelectedProducts.length">
            Selected Products : {{ userStoreSelectedProducts.length }}
          </div>
            <Button
              class=""
              @click="syncSelected()"
              :disabled="
                isLoadingSync ||
                isLoadingStoreProducts ||
                isLoadingSelectedStoreProducts
              "
              :isLoading="isLoadingSelectedStoreProducts"
              style="border-radius: 4px !important"
              v-if="userStoreSelectedProducts.length"
              >Sync Selected</Button
            >
          </div>
        </div>
      </div>
      <div class="loader px-2">
        <div
          class="d-flex"
          :class="[
            isLoadingStoreProducts
              ? 'justify-content-between'
              : 'justify-content-end',
          ]">
          <div class="d-flex align-items-center" v-if="isLoadingStoreProducts">
            <b-spinner small style="color: #4d1b7e"></b-spinner>
            <span class="ml-2">Loading Products</span>
          </div>
          
        </div>
      </div>
      <b-skeleton-table
        v-if="isLoadingProductFirstTime"
        :rows="5"
        :columns="3"
        :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
      <div class="data-table" v-else-if="userStoreProducts.length">
        <div
          class=""
          style="max-height: 55vh; min-height: 55vh; overflow: auto">
          <b-table
            @row-clicked="onRowClicked"
            head-class="text-center"
            class="leadTable"
            :tdClass="'cursor-pointer'"
            :stickyColumn="true"
            hover
            :fields="ProductsFields"
            :items="userStoreProducts">
            <template #head(checkbox)>
              <b-form-checkbox
                size="lg"
                :disabled="
                  isLoadingStoreProducts || isLoadingSelectedStoreProducts
                "
                v-model="storeSelectAllCheck"
                @change="shopifyProductSelectAllToggle($event)"
                class="checkbox selectAll"></b-form-checkbox>
            </template>
            <template #cell(checkbox)="data">
              <b-form-checkbox
                :value="data.item"
                v-model="userStoreSelectedProducts"
                :disabled="
                  isLoadingStoreProducts || isLoadingSelectedStoreProducts
                " 
                :id="data.item.id"
                size="lg"
                class="checkbox"></b-form-checkbox>
            </template>
            <template #cell(image)="data">
              <lazy-image
                class="circle-image mr-2"
                :src="
                  data.item.image
                    ? data.item.image
                    : 'https://images.quizell.com/website/default_start_page.png'
                "
                :key="data.item.image"></lazy-image>
            </template>
            <template #cell(title)="data">
              {{ data.item.title }}
            </template>
          </b-table>
        </div>
      </div>
                <template v-else>

<div class="d-flex justify-content-center align-items-center" style="height: 50vh;" >

<EmptyTable >
<template #title>
No product found
</template>
<template #description>
Time to fill your shelves or refine your search. Remember, your results will appear right here.
</template>
</EmptyTable>
</div>

</template>
       </div>
    </ModalVue>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { debounce } from "lodash";
import EmptyTable from "../../Layout/EmptyTable.vue";
import FilterBox from "../../Layout/FilterBox.vue";

import ModalVue from "../../Layout/Modal.vue";

import axios from "axios";
import Button from "../../Layout/Button.vue";

export default {
  components: {
    FilterBox,
    EmptyTable,
    ModalVue,
    Button,
  },
  props: {
    items: Array,
  },
  data() {
    return {
      selectAllQuizellProducts: false,

      searchProductText: "",

      isLoadingStoreProducts: false,
      isLoadingProductFirstTime: false,
      storeSelectAllCheck: false,
      userStoreSelectedProducts: [],

      userStoreProducts: [],
      ProductsFields: [
        {
          key: "checkbox",
          label: "",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "image",
          label: "",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "title",
          label: "Title",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading",
        },
      ],

      isLoadingSelectedStoreProducts: false,
      isLoadingDeleteProduct: false,
      isLoadingSync: false,
      isLoading: false,
    };
  },
  watch: {
    allProducts: {
      handler({ selected, all }) {
        this.selectAllQuizellProducts = all.every((product) =>
          selected.includes(product)
        );
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["getNavbarData"]),
    allProducts() {
      return {
        selected: this.userStoreSelectedProducts,
        all: this.userStoreProducts,
      };
    },

    shopifyAllProducts() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.userStoreProducts;
    },
  },
  methods: {
    onRowClicked(item){
      const isLoading= this.isLoadingSync ||
                this.isLoadingStoreProducts ||
                this.isLoadingSelectedStoreProducts
      if(!isLoading)
      {
 const index =this.userStoreSelectedProducts.indexOf(item);
      if(index !== -1)
        this.userStoreSelectedProducts.splice(index, 1);
      else
      this.userStoreSelectedProducts.push(item)
      }
    
    },
    openUserStoreProductModal() {
      this.isLoadingProductFirstTime = true;
      this.userStoreSelectedProducts = [];
      this.$refs.WooCommerceModal.open();
      this.getProductsFromUserStore();
    },
    async syncSelected() {
      this.isLoadingSelectedStoreProducts = true;
      await axios
        .post("/saveSelectedWordpressProducts", {
          products: JSON.stringify(this.userStoreSelectedProducts),
        })
        .then(() => {
          this.$refs.WooCommerceModal.close();
          this.$emit("callDBProducts");
          this.$toasted.show("Products sync successfully", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        })
        .catch((e) => {
          this.$toasted.show("Error occurred while syncing products", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          console.log(e);
        });
      this.isLoadingSelectedStoreProducts = false;
    },
    searchProductQueryHandle(e) {
      this.searchProductText = e;
      this.handleSearchDebounced();
    },
    shopifyProductSelectAllToggle() {
      this.userStoreSelectedProducts = [];
      if (this.storeSelectAllCheck) {
        this.userStoreSelectedProducts = this.shopifyAllProducts;
      }
    },

    async getProductsFromUserStore() {
      this.isLoadingStoreProducts = true;

      await axios
        .post("/loadWordpressProducts", { title: this.searchProductText })
        .then((response) => {
          this.userStoreProducts = response.data.data;
        })
        .catch((e) => {
          console.log(e);
        });
      this.isLoadingStoreProducts = false;
      this.isLoadingProductFirstTime = false;
    },

    handleSearchDebounced: debounce(function () {
      this.getProductsFromUserStore();
    }, 500), // 500ms debounce time, adjust as needed
  },
  mounted() {},
};
</script>
<style scoped>
.userStore-btn:active {
  background-color: #2e2f2f;
  color: #ffffff;
  border-color: #2e2f2f;
}
.userStore-btn {
  appearance: none;
  cursor: pointer;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #030303;
  color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;

  font-size: 14px;

  transition: 150ms ease;
  transition-property: background-color, border-color;
  display: inline-block;

  border-radius: 6px;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ffa201;
  background-color: #ffa201 !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem #ffa201;
}
.leadTable >>> thead {
  border-radius: 6px;
  color: black;
  background: rgba(179, 175, 182, 0.07);
  border: none;
}
.leadTable >>> thead tr {
  border-radius: 10px;
}
.leadTable >>> thead th {
  border: none;
  vertical-align: middle !important;
}
.loader {
  min-height:  12px;;
}
.product-image {
  height: 40px;
  width: 40px;
  /* border: 4px solid rgb(255, 255, 255); */
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  background-size: cover;
  /* padding: 0.25rem; */
  /* background-color: #fff; */
}
.customPagination >>> li > button {
  color: #4d1b7e;
}
.cursor-pointer{
  cursor: pointer !important;;
}
.customPagination >>> li.active > button {
  color: white;
  background-color: #4d1b7e !important;
  border-color: #4d1b7e;
}
.customPagination >>> li > button:hover {
  color: white;
  background-color: #6b4690 !important;
  border-color: #4d1b7e;
}
#product-detail >>> button {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}
#product-detail >>> button::after {
  display: none;
}
#product-detail >>> ul {
  background: #ffffff;
  box-shadow: 0px 10px 20px -3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
#product-detail >>> li.topbarLink {
  color: #344054;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}
#product-detail >>> li a:active {
  color: #fff;
  text-decoration: none;
  background-color: #4d1b7e;
}
</style>
